<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox" v-if="news">
        <h2>Duyurular</h2>

        <div class="duyuruline descline" v-if="loaded">
          <div class="duyurucard" v-for="(item, index) in news" :key="index">
            <div class="thumb" v-if="item.thumb">
              <img :src="item.thumb.src" alt="" />
            </div>

            <div class="duyurucontent">
              <h3 class="title" v-html="item.title" v-if="item.title"></h3>
              <div class="date" v-html="item.date" v-if="item.date"></div>

              <div
                class="desc"
                v-html="item.desc.join(' ')"
                v-if="item.desc"
              ></div>

              <div class="video" v-if="item.video">
                <iframe
                  width="100%"
                  :height="item.video.height || 315"
                  :src="item.video.src"
                  :title="item.video.title || ''"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div
                class="gallery"
                :class="{
                  'single-img': imgCount(item) == 1,
                  'double-img': imgCount(item) == 2,
                  'triple-img': imgCount(item) > 2,
                }"
                v-if="item.gallery && !item.imageNotClickable"
              >
                <img
                  v-for="(img, index2) in item.gallery"
                  v-img="{ group: index, thumbnails: true }"
                  :key="index2"
                  :src="img.src"
                  alt=""
                  @click="imgClick({ item, src: img.src })"
                />
              </div>

              <div
                class="gallery"
                :class="{
                  'single-img': imgCount(item) == 1,
                  'double-img': imgCount(item) == 2,
                  'triple-img': imgCount(item) > 2,
                }"
                v-if="item.gallery && item.imageNotClickable"
              >
                <img
                  v-for="(img, index2) in item.gallery"
                  :key="index2"
                  :src="img.src"
                  alt=""
                  @click="imgClick({ item, src: img.src })"
                />
              </div>

              <div class="duyurufooter">
                <div class="duyurulink" v-if="item.link">
                  <a :href="item.link.href" target="_blank">{{
                    item.link.desc || "Detaylı bilgi >"
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="pagination">
          <ul>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
          </ul>
        </div> -->
      </div>

      <!-- <div class="formbox" v-else>
        <h2>Duyurular</h2>
        Yükleniyor...
      </div> -->
    </div>
  </div>
</template>

<script>
const cdnURL = "https://cdn.iys.org.tr";
// const cdnURL = "https://cdn.vkk.io"; // TEST ORTAMI

const mockNews = [
  {
    no: "52",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title:
      "2024 yılı ISO denetimleri başarıyla tamamlandı.",
    date: "15 Ocak 2025",
    desc: [
      "Aralık ayı içinde denetimlerini başarıyla tamamladığımız ISO 22301 İş Sürekliliği Yönetim Standardı, ISO 20000-1 Bilgi Teknolojileri Hizmet Yönetim Sistemi Standardı, ISO 27001 Bilgi Güvenliği Yönetim Sistemi ve ISO 27701 Kişisel Veri Yönetim Sistemi belgeleri tarafımıza ulaşmıştır.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/250115-01.png",
      },
    ],
    imageNotClickable: true,
  },

  {
    no: "51",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title:
      "Ticari Elektronik İleti Yönetim Sistemi Entegratörleri Hakkında Tebliğ yayımlandı.",
    date: "18 Eylül 2024",
    desc: [
      "Ticari Elektronik İleti Yönetim Sistemi Entegratörleri Hakkında Tebliğ Resmî Gazete'de yayımlanarak yürürlüğe girdi. Tebliğe şu adresten ulaşabilirsiniz: <a class='blue' target='_blank' href= 'https://www.resmigazete.gov.tr/eskiler/2024/09/20240918-6.htm'>https://www.resmigazete.gov.tr/eskiler/2024/09/20240918-6.htm</a>",
    ],
    // gallery: [
    //   {
    //     src: cdnURL + "/assets/img/duyurular/230925-01.png",
    //   },
    // ],
  },

  {
    no: "50",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "Hizmet sağlayıcılarımızın kullandığı ekranlarda güncellemeler yapıldı.",
    date: "25 Haziran 2024",
    desc: [
      "Hizmet sağlayıcılarımızın kullandığı ekranda artık 'Doküman Kütüphanesi' yer almaktadır. Bu sayede hizmet sağlayıcı portalı ve yönetmeliklerle ilgili belgelere ulaşılabilecektir.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/240625-01.png",
      },
    ],
  },
  {
    no: "49",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "Hizmet sağlayıcılarımızın kullandığı ekranlarda güncellemeler yapıldı.",
    date: "04 Nisan 2024",
    desc: [
      "Hizmet sağlayıcılarımızın eriştiği ekranlarda kullanım kolaylığı sağlaması adına düzenlemeler yapıldı ve sayfaya Yardım menüsü eklendi.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/240404-01.png",
      },
    ],
  },

  {
    no: "48",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS API dokümantasyonu güncellendi.",
    date: "13 Şubat 2024",
    desc: [
      "Giriş sekmesi altındaki Canlı ortam kullanımı hakkında bölümünde içerik güncellemesi yapıldı. İYS API dokümantasyonuna bu linkten erişebilirsiniz: <a class='blue' target='_blank' href='https://apidocs.iys.org.tr'>apidocs.iys.org.tr</a>",
    ],
    // gallery: [
    //   {
    //     src: cdnURL + "/assets/img/duyurular/230925-01.png",
    //   },
    // ],
  },

  {
    no: "47",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title:
      "ISO 20000, ISO 22301, ISO 27001 ve ISO 27701 belgeleri tarafımıza ulaştı.",
    date: "01 Aralık 2023",
    desc: [
      // "Kasım ayının son haftasında denetimlerini başarıyla tamamladığımız ISO 22301 İş Sürekliliği Yönetim Standardı, ISO 20000-1 Bilgi Teknolojileri Hizmet Yönetim Sistemi Standardı, ISO 27001 Bilgi Güvenliği Yönetim Sistemi ve ISO 27701 Gizlilik Bilgi Yönetim Sistemi <a class='blue' target='_blank' href='https://cdn.iys.org.tr/assets/img/duyurular/sertifikalar.zip'>belgeleri</a> tarafımıza ulaşmıştır.",
      "Kasım ayının son haftasında denetimlerini başarıyla tamamladığımız ISO 22301 İş Sürekliliği Yönetim Standardı, ISO 20000-1 Bilgi Teknolojileri Hizmet Yönetim Sistemi Standardı, ISO 27001 Bilgi Güvenliği Yönetim Sistemi ve ISO 27701 Gizlilik Bilgi Yönetim Sistemi belgeleri tarafımıza ulaşmıştır.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/231201-01.png",
      },
    ],
    imageNotClickable: true,
  },

  {
    no: "46",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İleti VİA hizmetimize yeni metotlar eklendi.",
    date: "25 Eylül 2023",
    desc: [
      "İleti VİA hizmeti aracılığıyla e-posta üzerinden ret seçeneği sunmanıza ve çift doğrulama kodu ile tek mesajda onay almanıza imkân sağlayan yeni metotlarımız yayına alınmıştır. Yeni eklenen metotları İYS API dokümantasyonu (<a class='blue' target='_blank' href='https://apidocs.iys.org.tr'>apidocs.iys.org.tr</a>) üzerinden inceleyebilirsiniz.",
    ],
    // gallery: [
    //   {
    //     src: cdnURL + "/assets/img/duyurular/230925-01.png",
    //   },
    // ],
  },

  {
    no: "45",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İş Ortağı Portalı güncellendi.",
    date: "20 Mart 2023",
    desc: [
      "İş ortaklarımızın kullandığı ekranlarda kullanım kolaylığı sağlaması adına düzenlemeler yapıldı.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/230320-01.png",
      },
    ],
  },

  {
    no: "44",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS API güncellendi.",
    date: "10 Mart 2023",
    desc: [
      "API dokümantasyonumuzda yer alan mutabakat yönetimi ve hata kodları bölümlerinde güncellemeler yapılmıştır.",
    ],
    // gallery: [
    //   {
    //     src: cdnURL + "/assets/img/duyurular/230310-01.png",
    //   },
    // ],
  },

  {
    no: "43",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İş Ortağı Portalı'na VİA Demo ekranı eklendi.",
    date: "22 Şubat 2023",
    desc: [
      "İş ortaklarımızın müşterilerine İleti VİA hizmetinin demosunu yapabilmesine imkân verecek olan sayfa oluşturularak iş ortağı portalına eklendi.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/230222-01.png",
      },
    ],
  },

  {
    no: "42",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS’nin yeni hizmeti İleti ViA kullanıma açıldı.",
    date: "05 Ekim 2022",
    desc: [
      "İYS deneyiminizi verimli bir şekilde gerçekleştirebilmenize imkân tanıyan İleti ViA hizmetimiz, hizmet sağlayıcılarımızın ve iş ortaklarımızın kullanımına açılmıştır. Detaylı bilgi için <a class='blue' target='_blank' href='https://via.iletiys.com.tr'>via.iletiys.com.tr</a> adresini ziyaret edebilirsiniz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/221005-01.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    no: "41",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İş Ortağı Portalı yayına alınmıştır.",
    date: "27 Eylül 2022",
    desc: [
      "İş Ortaklarımızın İYS süreçlerini  yönetebilmesi amacıyla oluşturulan platformumuz <a class='blue' target='_blank' href='https://isortagi.iletiys.com.tr'>isortagi.iletiys.com.tr</a> adresinde yayına alınmıştır.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/220927-01.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    no: "40",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "Vatandaş portalında izin geçmişi geliştirmesi yapıldı.",
    date: "04 Mart 2022",
    desc: [
      "Vatandaşlarımızın vermiş oldukları onay veya redde ilişkin geçmiş bilgisini görüntülemesine imkân veren geliştirme tamamlandı.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/220304-01.png",
      },
    ],
  },
  {
    no: "39",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title: "ISO 27701 ve ISO 20000 belgeleri tarafımıza ulaştı.",
    date: "26 Kasım 2021",
    desc: [
      "Eylül ayının son haftasında denetimlerini başarıyla tamamladığımız ISO 27701 Kişisel Veri Yönetim Sistemi ve ISO 20000 BT Hizmet Yönetim Sistemi belgeleri tarafımıza ulaşmıştır.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/211226-01.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    no: "38",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "İYS üzerinden sabit telefon numaralarınız için de ticari elektronik ileti izinlerinizi kontrol edebilirsiniz.",
    date: "8 Temmuz 2021",
    desc: [
      "İYS'ye <a class='blue' target='_blank' href='https://vatandas.iys.org.tr'>giriş yaparken</a> sabit numaraya bağlı izinlerinizi görüntülemek için sesli arama yoluyla da doğrulama kodu alabilirsiniz. Bunun için, sabit numaranızı girdikten sonra <b>&#34;Doğrulama için beni ara&#34;</b> butonuna tıklayarak doğrulama kodunu alabilirsiniz. ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210601-04.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    no: "37",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "Hizmet sağlayıcılarımızın kullandığı ekranlarda güncellemeler yapıldı.",
    date: "6 Temmuz 2021 ",
    desc: [
      "Hizmet sağlayıcılarımız artık marka yönetimi ekranı altında eklemiş oldukları iletişim adreslerini; izin tarihi, izin kaynağı, izin durumu ve tarih alanlarına göre filtreleyip sıralayabileceklerdir.",
      "<br>",
      "<br>",
      " Ek olarak abonelik yönetimi ekranları, hizmet sağlayıcılarımızın her bir markası için sahip olduğu adres sayısını, telefon numarasını ve e-posta adresini görebilecekleri şekilde düzenlenmiştir.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210706-01.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/210601-03.png",
      },
    ],
  },
  {
    no: "36",
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "İYS başvurusunu tamamlamış tüm işletmeleri 1 Haziran 2021 itibarıyla İleti Yönetim Sistemi’nde görüntüleyebilirsiniz.",
    date: "1 Haziran 2021",
    desc: [
      // "<a class='blue'  target='_blank' href='https://vatandas.iys.org.tr'>https://vatandas.iys.org.tr</a> veya e-Devlet Kapısı üzerinden <a class='blue'  target='_blank' https://turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi>https://turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi</a> hizmeti aracılığıyla iletişim adreslerinize ait tüm ticari elektronik onayları görüntüleyebilir ve düzenleyebilirsiniz.",
      "<a class='blue'  target='_blank' href='https://vatandas.iys.org.tr'>https://vatandas.iys.org.tr</a> hizmeti aracılığıyla iletişim adreslerinize ait tüm ticari elektronik onayları görüntüleyebilir ve düzenleyebilirsiniz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210601-01.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    no: "35",
    active: false,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "İleti Yönetim Sistemi üzerinden yeni izin ekleme akışı kullanıma sunuldu.",
    date: "6 Mayıs 2021",
    desc: [
      "Vatandaşlarımız <a class='blue'  target='_blank' href='https://vatandas.iys.org.tr'>https://vatandas.iys.org.tr</a> veya e-Devlet Kapısı üzerinden Ticari Elektronik İleti Yönetim Sistemi hizmeti aracılığıyla yeni bir hizmet sağlayıcıya ticari elektronik ileti onayı verebilirsiniz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210506-01.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/210506-02.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_video.png",
    },
    title: "TRT Radyo 1’de Eren Bilal ile Dijital Hayat’ın konuğu olduk.",
    date: "26 Mart 2021",
    desc: [
      "Genel Müdürümüz Dr. Tolga TÜFEKÇİ, TRT Radyo 1’de Eren Bilal ile ",
      "Dijital Hayat’ın konuğu oldu. Yayının tamamını buradan dinleyebilirsiniz",
    ],
    video: {
      src: "https://www.youtube.com/embed/McaC2E9GsyU",
      height: "315",
      title: "Eren Bilal ile Dijital Hayat",
    },
  },

  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "Ticaret Bakanlığımız, İleti Yönetim Sistemi’ne dair bir bilgilendirme duyurusu paylaştı.",
    date: "02 Mart 2021",
    desc: [
      "Ticaret Bakanlığımızın Ticari Elektronik İleti Yönetim Sistemine",
      "(İYS) İlişkin <a href='https://www.eticaret.gov.tr/haberler/10077/detay' class='blue' target='_blank'>Bilgilendirme Duyurusu</a>: ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210302-01.png",
      },
    ],
    imageNotClickable: true,
    // link: {
    //   desc: "Duyuruyu oku >",
    //   href: "https://www.eticaret.gov.tr/haberler/10077/detay",
    // },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title: "ISO 27001 ve ISO 22301 belgeleri tarafımıza ulaştı.",
    date: "17 Şubat 2021",
    desc: [
      "Ocak ayının 3. haftasında denetimlerini başarıyla tamamladığımız",
      "ISO 27001 Bilgi Güvenliği Yönetim Sistemi ve ISO 22301 İş Sürekliliği",
      "Yönetim Sistemi belgeleri tarafımıza ulaştı.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210217-01.png",
      },
    ],
    imageNotClickable: true,
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title:
      "Ticaret Bakanımız Sayın Ruhsar Pekcan, İleti Yönetim Sistemi’ne dair istatistikleri paylaştı.",
    date: "26 Mart 2021",
    desc: [
      "İstenmeyen SMS, sesli arama ve e-posta gibi ticari elektronik iletilerin önüne geçmek amacıyla 7 Ocak’ta hizmete açılan Ticari Elektronik İleti Yönetim Sistemine (İYS) vatandaşlarımızın büyük ilgi gösterdiğini belirten Ticaret Bakanımız Sayın Ruhsar Pekcan, İleti Yönetim Sistemi’ne dair istatistikleri paylaştı. Bugüne kadar 2 milyonun üzerinde vatandaşımız sisteme girerek onaylarını kontrol etti, 32,2 milyon ret işlemi ve 300 bine yakın onay işlemi gerçekleşti.",
    ],
    link: {
      desc: "Kaynak >",
      href: "https://twitter.com/pekcan/status/1361942652229455874",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_video.png",
    },
    title: "Arama, mesaj ve e-postalar sadece isteğiniz işletmelerden gelsin!",
    date: "1 Şubat 2021",
    desc: [
      "Sizler için hazırladığımız tanıtım videomuza şu adresten ulaşabilirsiniz: ",
    ],
    video: {
      src: "https://www.youtube.com/embed/kjcGItOtOBU",
      height: "315",
      title:
        "Arama, mesaj ve e-postalar sadece isteğiniz işletmelerden gelsin!",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_sunum.png",
    },
    title:
      "Elektronik Ticaret İşletmecileri Derneği’nin düzenlediği ETİD MasterTalks'a konuk olduk.",
    date: "26 Mart 2021",
    desc: [
      "Canlı olarak gerçekleşen webinar’da İleti Yönetim Sistemi hakkında güncel durumu ",
      "ve bu süreçte e-ticaret müşterilerinin ve e-ticaret girişimcilerinin neler yapabileceğini konuştuk.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210128-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "Gelenekselden Yeni Normale Yolculuk başlıklı TOBB Dijitalleşme Sanal Fuarı’nda yerimizi aldık.",
    date: "26-27 Ocak 2021",
    desc: [
      "Türkiye Odalar ve Borsalar Birliği Başkanı Sn. M. Rifat Hisarcıklıoğlu ",
      "ve Ticaret Bakanımız  Sn. Ruhsar Pekcan’ın da katılım gösterdiği Gelenekselden ",
      "Yeni Normale Yolculuk başlıklı TOBB Dijitalleşme Sanal Fuarı’nda yer alarak ",
      "ziyaretçilerimizin sorularını yanıtladık.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/210127-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İstenmeyen mesajları engelleyecek sistem devreye girdi.",
    date: "12 Ocak 2021",
    desc: ["TRT Haber’in haberine şu adresten ulaşabilirsiniz: "],
    link: {
      desc: "TRT Haber >",
      href: "https://www.trthaber.com/haber/bilim-teknoloji/istenmeyen-mesajlari-engelleyecek-sistem-devrede-546522.html",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İleti Yönetim Sistemi Vatandaşlarımızın kullanımına açılmıştır!",
    date: "7 Ocak 2021",
    desc: [
      "<a href='https://vatandas.iys.org.tr' class='blue' target='_blank'>https://vatandas.iys.org.tr</a>",
      " hizmeti aracılığıyla",
      " iletişim adreslerinize ait tüm ticari elektronik onayları görüntüleyebilir ve ",
      " düzenleyebilirsiniz. 150 bin adedin üstünde ticari elektronik ileti onayına sahip",
      " hizmet sağlayıcıların İYS’ye yükledikleri onayları 16 Şubat 2021 tarihine kadar kontrol",
      " edebilirsiniz. Bu tarihe kadar ret hakkını kullanmamanız halinde İYS’ye aktarılan",
      " onaylarınız geçerli sayılacaktır. 16 Şubat 2021'den sonra da &#34;dilediğiniz zaman&#34; ",
      " İYS üzerinden ticari elektronik ileti tercihlerinizi değiştirebilirsiniz.",
    ],
    // desc: [
    //   "<a href='https://vatandas.iys.org.tr' class='blue' target='_blank'>https://vatandas.iys.org.tr</a> veya e-Devlet Kapısı üzerinden ",
    //   "<a href='https://turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi' class='blue'  target='_blank'>https://turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi</a> hizmeti aracılığıyla",
    //   " iletişim adreslerinize ait tüm ticari elektronik onayları görüntüleyebilir ve ",
    //   " düzenleyebilirsiniz. 150 bin adedin üstünde ticari elektronik ileti onayına sahip",
    //   " hizmet sağlayıcıların İYS’ye yükledikleri onayları 16 Şubat 2021 tarihine kadar kontrol",
    //   " edebilirsiniz. Bu tarihe kadar ret hakkını kullanmamanız halinde İYS’ye aktarılan",
    //   " onaylarınız geçerli sayılacaktır. 16 Şubat 2021'den sonra da &#34;dilediğiniz zaman&#34; ",
    //   " İYS üzerinden ticari elektronik ileti tercihlerinizi değiştirebilirsiniz.",
    // ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200107-02.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "TOBB ev sahipliğinde düzenlenen Veri Güvenliği paneline konuk olduk.",
    date: "1 Aralık 2020",
    desc: [
      "Genel Müdürümüzün de katılımıyla Türkiye Odalar ve Borsalar Birliği’nin ",
      "düzenlediği E-Ticarette E-Kılavuz Programı kapsamında, veri güvenliği konusunun ",
      "uygulamadaki örneklerinin konuşulduğu Veri Güvenliği paneline konuk olduk.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/201201-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title: "İleti Yönetim Sistemi’ne ilişkin süreler ertelendi.",
    date: "30 Kasım 2020",
    desc: [
      "Hizmet sağlayıcıların Ticari Elektronik İleti Yönetim Sistemine (İYS) kaydolma ",
      "ve mevcut onaylarını yükleme tarihi ertelenmiştir.",
      "Bakanlığımızın duyurusuna göre;",
      "<br><br>",
      "150 bin adedin üstünde ticari elektronik ileti onayı olan hizmet sağlayıcılar ",
      "için onay yükleme son tarihi 31 Aralık 2020 tarihine; bu onayların vatandaş ",
      "tarafından kontrol edilme son tarihi ise 15 Şubat 2021 tarihine,",
      "<br><br>",
      "150 bin adet ve altında ticari elektronik ileti onayı olan hizmet sağlayıcılar için ",
      "onay yükleme son tarihi 31 Mayıs 2021 tarihine; bu onayların vatandaş tarafından kontrol ",
      "edilme son tarihi ise 15 Temmuz 2021 tarihine ertelenmiştir. T.C. Ticaret Bakanlığımızın ",
      "duyurusuna buradan ulaşabilirsiniz: ",
      "<a href='https://ticaret.gov.tr/haberler/ticari-elektronik-ileti-yonetim-sistemine-iys-iliskin-basin-aciklamasi' class='blue'  target='_blank' >https://ticaret.gov.tr/haberler/ticari-elektronik-ileti-yonetim-sistemine-iys-iliskin-basin-aciklamasi</a>",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/201130-01.png",
      },
    ],
    imageNotClickable: true,
    // link: {
    //   desc: "Basın açıklaması >",
    //   href: "https://ticaret.gov.tr/haberler/ticari-elektronik-ileti-yonetim-sistemine-iys-iliskin-basin-aciklamasi",
    // },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "KMD’nin öncülüğünde düzenlenen Perakende Teknolojileri Konferansı’na katıldık.",
    date: "11 Kasım 2020",
    desc: [
      "Kategori Mağazacılığı Derneği’nin 7.sini düzenlediği Perakende Teknolojileri ",
      "Konferansı’nın &#34;Her Şey Dijitalleşiyor, Peki ya Kurallar?&#34; başlıklı oturumuna ",
      "katılarak İleti Yönetim Sistemi’nin sektördeki etkilerini ve İYS’nin güncel durumunu konuştuk. ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/201111-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_video.png",
    },
    title:
      "İşletmeler için İYS Mevzuat'ını özetlediğimiz videomuzu izleyebilirsiniz.",
    date: "9 Kasım 2020",
    desc: [
      "İşletmelerin ticari elektronik ileti onaylarını İYS'ye yüklemesi için son tarih 30 Kasım 2020'dir! ",
      "Mevzuat'a dair daha fazla bilgi için videomuzu izleyebilirsiniz: ",
    ],
    video: {
      src: "https://www.youtube.com/embed/XFjXIbJ0Tfc",
      height: "315",
      title: "YouTube video player",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_sunum.png",
    },
    title: "TAMPF ev sahipliğinde düzenlenen bir webinar’a katıldık.",
    date: "22 Ekim 2020",
    desc: [
      "Türkiye Alışveriş Merkezleri ve Perakendeciler Federasyonu iş birliği ve ",
      "Genel Müdürümüzün katılımıyla <b>“Ticari Elektronik İletilerde Yeni Dönem - ",
      "İleti Yönetim Sistemi Perakende Sektörü için Neler Getiriyor”</b> başlıklı bir ",
      "webinar düzenlendi.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/201022-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title:
      "İLETİ-25 ve İLETİ-75 paketleri 1 yıl süreyle ücretsiz olarak sunuldu. ",
    date: "20 Ekim 2020",
    desc: [
      "1 Aralık 2020 tarihine kadar başvurusunu tamamlayan tüm hizmet sağlayıcılara ",
      "<b>İLETİ 5</b>, <b>İLETİ 25</b> ve <b>İLETİ 75</b>, Seviye – 1 hizmetleri bir ",
      "yıl süreyle ücretsiz olarak sunulmuştur.",
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title: "İleti Yönetim Sistemi A.Ş. Yönetim Beyanı yayımlandı.",
    date: "19 Ekim 2020",
    desc: ["İleti Yönetim Sistemi A.Ş. Yönetim Beyanı yayımlandı. "],
    link: {
      desc: "Ulaşmak için >",
      href: "https://iys.org.tr/iys/hakkimizda",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "Hizmet sağlayıcılar için elektronik imzanın yanında e-Devlet ile başvuru yolu açıldı.",
    date: "28 Eylül 2020",
    desc: [
      "MERSİS numarası bulunan hizmet sağlayıcılarımız elektronik imzanın yanı sıra artık ",
      "e-Devlet hesaplarıyla da giriş yaparak <a href='https://iys.org.tr/hizmet-saglayici/basvuru/nasil-yapilir' class='blue'  target='_blank'>",
      "https://iys.org.tr/hizmet-saglayici/basvuru/nasil-yapilir</a> adresinden ",
      "e-Devlet Girişi butonuna tıklayarak başvurularını hızlıca tamamlayabilirler.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200928-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS API’de mutabakat servisleri test ortamında yayına alındı.",
    date: "11 Eylül 2020",
    desc: [
      "Hizmet sağlayıcılarımız ve iş ortaklarımız için izin hareketi sorgulama ve mutabakat servisleri test ortamında yayına alınmıştır.",
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title: "İleti Yönetim Sistemi’ne ilişkin süreler ertelendi.",
    date: "28 Ağustos 2020",
    desc: [
      "Resmî Gazete'de yayımlanan Yönetmelik'le birlikte hizmet sağlayıcıların ",
      "geçmiş tarihli onaylarını İYS'ye yükleyebileceği son tarih 30 Kasım 2020 ",
      "tarihine ertelenmiştir.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200828-01.png",
      },
    ],
    link: {
      desc: "Bakanlık duyurusu >",
      href: "https://www.eticaret.gov.tr/duyurular/10015/detay",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS API güncellendi.",
    date: "18 Ağustos 2020",
    desc: [
      "Hizmet sağlayıcılarımızın İYS'ye ilettiği veya vatandaşlarımız tarafından İYS üzerinde ",
      "gerçekleştirilen izin hareketlerini sorgulamayı sağlayan İzin Hareketi Sorgulama (Pull) ",
      "metodu İYS API dokümantasyonunda (<a href='https://apidocs.iys.org.tr' class='blue'  target='_blank'>https://apidocs.iys.org.tr</a>) yayına alınmıştır. ",
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title: "İYS Geliştirici Merkezi yayına alındı.",
    date: "29 Temmuz 2020",
    desc: [
      "Ticari elektronik İleti izinlerinin İYS'ye aktarımı sırasında dikkat edilmesi gereken ",
      "doğrulama kurallarına ve API Dokümantasyonu'nda açıklanan API isteklerine dair doğru ve ",
      "hatalı örneklere aşağıdaki linkten ulaşabilirsiniz: ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200729-01.png",
      },
    ],
    link: {
      desc: "Api dökümantasyonu >",
      href: "https://dev.iys.org.tr",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İş ortaklarımız web sitemizde ilan edildi.",
    date: "23 Temmuz 2020",
    desc: [
      "İş ortaklarımız güncel web sitemizde ilan edilmiştir. ",
      "<a href='https://iys.org.tr/is-ortaklari' class='blue'  target='_blank' >https://iys.org.tr/is-ortaklari</a> adresinden ",
      "iş ortaklarımızı görüntüleyebilirsiniz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200723-03.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200723-02.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200723-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS, hizmet sağlayıcılarımız için canlı ortamda kullanıma açıldı.",
    date: "23 Temmuz 2020",
    desc: [
      "İleti Yönetim Sistemi hizmet sağlayıcılarımız için canlı ortamda kullanıma açılmıştır. ",
      "Başvurusu onaylanan hizmet sağlayıcılarımız e-Devlet hesaplarını kullanarak veya API ",
      "üzerinden erişim sağlayarak İYS'ye giriş yapabileceklerdir.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200723-04.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200723-05.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "Türkiye Barolar Birliği tarafından düzenlenen webinar’a katılım gösterdik.",
    date: "9 Haziran 2020",
    desc: [
      "Genel Müdürümüz, Türkiye Barolar Birliği tarafından düzenlenen Online Eğitim Programı ",
      "çerçevesinde İleti Yönetim Sistemi üzerine bir konuşma yaptı. Ev sahipliği için Av. Şadan Tutumlu ve ",
      "Türkiye Barolar Birliği'ne teşekkür ederiz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200609-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "İleti Yönetim Sistemi’ne dair son gelişmelerin paylaşıldığı canlı bir webinar düzenlendi.",
    date: "28 Mayıs 2020",
    desc: [
      "Genel Müdürümüz Dr. Tolga TÜFEKÇİ’nin katılımcılardan gelen soruları yanıtladığı ",
      "bir Webinar gerçekleştirdik. Katkıları ve ev sahipliği için Av. Ceren Avunduk'a teşekkür ",
      "ederiz. Webinar’ın tamamını bu adresten izleyebilirsiniz: ",
    ],
    video: {
      src: "https://www.youtube.com/embed/5tu3VmVQbsI",
      height: "315",
      title: "Webinar",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title: "İleti Yönetim Sistemi’ne ilişkin süreler ertelendi.",
    date: "23 Mayıs 2020",
    desc: [
      "4 Ocak 2020 tarihli Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelikte ",
      "Değişiklik Yapılmasına Dair Yönetmelik Geçici Madde 2'de yer alan tarihler Bakanlığımızın ",
      "duyurusuyla birlikte 3 ay ertelenmiştir. Buna göre, onayların yüklenmesi için geçerli son ",
      "tarih 31 Ağustos 2020’ye ertelendi. Bakanlığımızın duyurusuna buradan ulaşabilirsiniz: ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200523-01.png",
      },
    ],
    link: {
      desc: "Duyuru >",
      href: "https://www.eticaret.gov.tr/duyurular/10012/detay",
    },
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "İYS API test ortamında yayına alındı.",
    date: "13 Nisan 2020",
    desc: [
      "Hizmet sağlayıcılar için İYS API test ortamını bugün itibarıyla yayına ",
      "aldık. Hizmet sağlayıcılarımız, API Dokümantasyonunu takip ederek ",
      "(<a href='http://apidocs.iys.org.tr' class='blue' target='_blank' >http://apidocs.iys.org.tr</a>) API'yi kullanabilirler.",
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_sunum.png",
    },
    title: "MEF Üniversitesi’nde düzenlenen panelde bir sunum yaptık.",
    date: "11 Mart 2020",
    desc: [
      "E-Ticaret ve İnternet Hukuku Derneği’nin düzenlemiş olduğu E-Hukuk Sohbetleri",
      "serisinin 8.si düzenlendi. Genel Müdürümüzün konuşmacı olduğu konferansta &#34;İleti ",
      "Yönetim Sistemi Neler Getiriyor?&#34; başlıklı bir sunum yapıldı.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200311-01.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200311-02.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200311-03.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "YASED’in öncülüğünde II. Kişisel Verilerin Korunması & AB Genel Veri Koruma Tüzüğü Konferansı düzenlendi.",
    date: "5 Mart 2020",
    desc: [
      "Genel Müdürümüz Dr. Tolga TÜFEKÇİ, Uluslarası Yatırımcılar Derneği tarafından ",
      "düzenlenen II. Kişisel Verilerin Korunması & AB Genel Veri Koruma Tüzüğü Konferansı'nda bir ",
      "konuşma yaparak İleti Yönetim Sistemi hakkında bilgiler verdi.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200305-01.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200305-02.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_sunum.png",
    },
    title:
      "TOBB iş birliğinde Türkiye Sektör Meclisleri’yle geniş katılımlı bir seminer düzenledik.",
    date: "4 Mart 2020",
    desc: [
      "Türkiye Odalar ve Borsalar Birliği’nin organizasyonuyla üç farklı oturumda düzenlenen ",
      "İleti Yönetim Sistemi – Sektör Meclisleri Bilgilendirme seminerlerine 7 farklı sektörden ",
      "200’e yakın kişi katılım gösterdi.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200304-01.png",
      },
      {
        src: cdnURL + "/assets/img/duyurular/200304-02.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_event.png",
    },
    title:
      "İleti Yönetim Sistemi kapsamındaki gelişmelerle ilgili Türkiye Bankalar Birliği’yle bir araya geldik.",
    date: "3 Mart 2020",
    desc: [
      "Türkiye Bankalar Birliği'nin ev sahipliğinde bankalarımıza İleti Yönetim Sistemi'nin ",
      "işleyişini (İYS) anlattık. Gelişmelere ilişkin fikir alışverişinde bulunduk. ",
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_sunum.png",
    },
    title:
      "İstanbul Barosu’nda E-Ticarette Güncel Hukuki Gelişmeler başlıklı bir seminer düzenlendi.",
    date: "25 Şubat 2020",
    desc: [
      "İstanbul Barosu’nun düzenlediği E-Ticarette Güncel Hukuki Gelişmeler başlıklı",
      "seminere katılarak İleti Yönetim Sistemi’nin işleyişine dair son gelişmeleri paylaştık.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200225-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_duyuru.png",
    },
    title: "Hizmet sağlayıcılar için İleti Yönetim Sistemi Başvuruları açıldı.",
    date: "31 Ocak 2020",
    desc: [
      "Hizmet sağlayıcılar için İleti Yönetim Sistemi başvuruları açıldı. ",
      "<a href='https://iys.org.tr/hizmet-saglayici/basvuru/nasil-yapilir'  class='blue' target='_blank' >https://iys.org.tr/hizmet-saglayici/basvuru/nasil-yapilir</a> ",
      "adresinden gerekli adımları takip ederek e-imzanızla kaydınızı tamamlayabilirsiniz.",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200131-01.png",
      },
    ],
  },
  {
    active: true,
    thumb: {
      src: cdnURL + "/assets/img/duyurular/_dokuman.png",
    },
    title:
      "Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelikte değişiklik yapıldı.",
    date: "04 Ocak 2020",
    desc: [
      "Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelikte Değişiklik ",
      "Yapılmasına Dair Yönetmelik 4 Ocak 2020 tarihli 30998 sayılı Resmî Gazete’de yayımlandı. ",
    ],
    gallery: [
      {
        src: cdnURL + "/assets/img/duyurular/200104-01.png",
      },
    ],
    link: {
      desc: "Resmî Gazete >",
      href: "https://resmigazete.gov.tr/eskiler/2020/01/20200104-2.htm",
    },
  },

  // {
  //     "active": true,
  //     "thumb": {
  //         "src": cdnURL + "/assets/img/duyurular/xxxxxx.png"
  //     },
  //     "title": "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  //     "date": "XXXXXXXXXXXXXXXXXXXX",
  //     "desc": [
  //         "İstenmeyen SMS, sesli arama ve e-posta gibi ticari elektronik",
  //         "iletilerin önüne geçmek amacıyla 7 Ocak’ta hizmete açılan Ticari",
  //         "Elektronik İleti Yönetim Sistemine (İYS) vatandaşlarımızın büyük",
  //         "ilgi gösterdiğini belirten Ticaret Bakanımız Sayın Ruhsar",
  //         "Pekcan, <span class='blue'>İleti Yönetim Sistemi</span>’ne dair",
  //         "istatistikleri paylaştı. Bugüne kadar 2 milyonun üzerinde",
  //         "vatandaşımız sisteme girerek onaylarını kontrol etti, 32,2",
  //         "milyon ret işlemi ve 300 bine yakın onay işlemi gerçekleşti."
  //     ],
  //     "video": {
  //         "src": "https://www.youtube.com/embed/McaC2E9GsyU",
  //         "height": "315",
  //         "title": "YouTube video player"
  //     },
  //     "gallery": [
  //         {
  //             "src": cdnURL + "/assets/img/duyurular/200723-02.png"
  //         },
  //         {
  //             "src": cdnURL + "/assets/img/duyurular/200723-02.png"
  //         }
  //     ],
  //     "link": {
  //         "desc": "Buraya gidiniz >",
  //         "href": "https://google.com"
  //     }
  // },
];

import { mapMutations } from "vuex";

export default {
  name: "Duyurular",
  data() {
    return {
      page: 1,
      pagination: null,
      news: null,
      loaded: false,
    };
  },

  created() {
    if (this.$env.dev) {
      this.news = mockNews.filter((e) => e.active);
      this.loaded = true;
    } else {
      this.news = mockNews.filter((e) => e.active);
      this.loaded = true;
      // this.GET_NEWS();
    }
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_MODAL_OPTIONS"]),

    imgCount(item) {
      if (!item.gallery) return 0;
      return item.gallery.length;
    },

    imgClick({ item, src }) {
      //   this.SET_MODAL_OPTIONS({ item, src });
      //   this.SET_MODAL("ModalDuyuruImg");
    },

    GET_NEWS() {
      this.$nextTick(() => {
        this.$store
          .dispatch("applicant/get_news", { page: this.page })
          .then((res) => {
            if (res.ok) {
              this.$env.CONSOLE.warn("News Response:", res);
              this.news = [...res.payload.list];
              this.pagination = res.payload.pagination;
              this.loaded = true;
            }
          });
      });
    },
  },
};

// [
//     {
//         "active": true,
//         "thumb": {
//             "src": "http://mobil.tobb.org.tr/HaberResimleri/9582-3.jpg"
//         },
//         "title": "Başlık <b>1</b>",
//         "date": "26 Mart <b>2021</b>",
//         "desc": [
//             "İstenmeyen SMS, sesli arama ve e-posta gibi ticari elektronik",
//             "iletilerin önüne geçmek amacıyla 7 Ocak’ta hizmete açılan Ticari",
//             "Elektronik İleti Yönetim Sistemine (İYS) vatandaşlarımızın büyük",
//             "ilgi gösterdiğini belirten Ticaret Bakanımız Sayın Ruhsar",
//             "Pekcan, <span class='blue'>İleti Yönetim Sistemi</span>’ne dair",
//             "istatistikleri paylaştı. Bugüne kadar 2 milyonun üzerinde",
//             "vatandaşımız sisteme girerek onaylarını kontrol etti, 32,2",
//             "milyon ret işlemi ve 300 bine yakın onay işlemi gerçekleşti."
//         ],
//         "video": {
//             "src": "https://www.youtube.com/embed/McaC2E9GsyU",
//             "height": "315",
//             "title": "YouTube video player"
//         },
//         "gallery": [
//             {
//                 "src": "http://mobil.tobb.org.tr/HaberResimleri/9580-1.jpg"
//             },
//             {
//                 "src": "http://mobil.tobb.org.tr/HaberResimleri/9582-2.jpg"
//             }
//         ],
//         "link": {
//             "desc": "Buraya gidiniz >",
//             "href": "https://google.com"
//         }
//     },
// ]
</script>

<style scoped>
html,
body {
  scroll-padding-top: 100px;
}

.formbox {
  margin-bottom: 100px;
}

.duyuruline {
  display: block;
  padding: 0 200px;
}

.duyurucard {
  display: flex;
  background: #fff;
  padding: 20px;
  margin: 0 0 2rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0 1px 6px -3px rgba(0, 0, 0, 0.3);
}

.duyurucard .thumb {
  width: 70px;
  height: 70px;
  min-width: 70px;
  margin: 0 20px 0 0;
}

.duyurucard .duyurucontent {
  width: 100%;
}

.duyurucard .thumb img {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 10px !important;
  object-fit: cover;
  box-shadow: 0 0 2px 0 #ccc;
}

.duyurucard h3.title {
  font-size: 16px !important;
  color: #55565e;
  margin: 0 0 0 0;
}

.duyurucard .date {
  font-size: 0.9rem;
  color: #acadb7;
  margin: 10px 0;
}

.duyurucard .desc {
  font-size: 1.1rem;
  padding-bottom: 10px;
}

.duyurucard .video {
  margin: 10px 0;
}

.duyurucard .gallery {
  margin: 10px 0;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.duyurucard .gallery img {
  padding: 0;
  object-fit: contain;
  border-radius: 10px;
  background: #fdfbfa;
  border: 1px solid #ccc;
}

.duyurucard .gallery.triple-img img {
  height: 160px;
  width: 31.5%;
  object-fit: cover;
}

.duyurucard .gallery.double-img img {
  max-height: 240px;
  width: 46%;
}

.duyurucard .gallery.single-img img {
  height: unset;
  max-height: 260px;
  width: 100%;
}

.duyurucard .gallery.single-img {
  justify-content: flex-start;
}

.duyurucard .duyurufooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.duyurucard .duyurulink a {
  font-weight: 600;
  color: #4da0df;
  padding: 0.6rem;
  border-radius: 5px;
}

.duyurucard .duyurulink a:hover {
  color: #2287d4;
  background: #f1f1f1;
}

.duyurucard .video {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.duyurucard .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.pagination li {
  padding: 6px;
  border-radius: 5px;
}

.pagination li:hover {
  color: #2287d4;
  background: #f1f1f1;
}

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .duyuruline {
    padding: 0 5px;
  }

  .duyurucard .thumb {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin: 0 10px 0 -25px;
    display: none;
  }

  .duyurucard {
    padding: 15px;
  }
}
</style>
